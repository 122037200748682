<template>
  <div class="answer-mode">
    <div class="answer-wrapper manage-wrapper">
      <div class="container">
        <div class="explain">
          <div class="explain_title">{{ workInfo.workname }}</div>
          <div class="explain_item">作业主题：{{ workTheme }}</div>
          <div class="explain_item flex-layout">
            <div style="flex-shrink:0;">作业说明：</div>
            <div v-html="explain"></div>
          </div>
          <div class="explain_item">
            提交时间：{{ workInfo.beginTime }} --- {{ workInfo.endTime }}
          </div>
          <div class="explain_item">积分：100积分</div>
          <div class="explain_item">能力标准：{{ powers }}</div>

        </div>
        <!-- 中部生成的表单 -->
        <div class="center-board">
          <div class="workContent">
            <span>作业内容</span>
          </div>
          <!-- 生成的表单 -->
          <el-scrollbar class="center-scrollbar">
            <el-row class="center-board-row" :gutter="formConf.gutter">
              <el-form :size="formConf.size" :label-position="formConf.labelPosition" :disabled="formConf.disabled"
                :label-width="formConf.labelWidth + 'px'">
                <draggable class="drawing-board" :list="drawingList" :animation="340" group="componentsGroup"
                  :options="dragOptions">
                  <draggable-item v-for="(item, index) in drawingList" :key="item.renderKey" :drawing-list="drawingList"
                    :current-item="item" :index="index" :active-id="activeId" :form-conf="formConf"
                    @activeItem="activeFormItem" @copyItem="drawingItemCopy" @deleteItem="drawingItemDelete" />
                  <div class="fujian" v-for="(item, index) in fujianList" :key="index">
                    <div style="margin-left: 10px" class="fujian_title">
                      <div style="margin-bottom: 10px">
                        {{ item.title }}
                      </div>
                      <div class="fjexplin" v-if="item.explain">
                        {{item.explain}}
                      </div>
                      <!-- 图片上传 -->
                      <div class="mask-wrap" style="display: inline-block" v-for="(items, index) in item.datalist.filter((item) => {
                        return picTypes.indexOf(item.type.toLowerCase()) != -1;
                      })" :key="'2' + index">
                        <div class="mask">
                          <i class="el-icon-delete" style="color: #ffffff; font-size: 16px"
                            @click="deletefujian(item.id, items)"></i>
                        </div>
                        <el-image v-if="picTypes.indexOf(items.type.toLowerCase()) != -1"
                          style="width: 100%; height: 100%" :src="imgurl + items.url" :preview-src-list="item.srclist">
                        </el-image>
                      </div>
                      <!-- 视频上传 -->
                      <div class="video-wrap" v-for="(items, index) in item.datalist.filter((item) => {
                        return videoTypes.indexOf(item.type.toLowerCase()) != -1;
                      })" :key="'3' + index">

                        <!-- 删除按钮 -->
                        <div class="mask">
                          <i class="el-icon-delete" style="color: #ffffff; font-size: 16px"
                            @click="deletefujian(item.id, items)"></i>
                        </div>
                        <video v-if="videoTypes.indexOf(items.type.toLowerCase()) != -1" :src="imgurl + items.url"
                          controls="controls" width="200px" height="150"
                          style="display: block; margin: 10px 20px 10px 0">
                          您的浏览器不支持播放该视频！
                        </video>
                      </div>
                      <!-- 音频上传 -->
                      <div class="audio-wrap" v-for="(items, index) in item.datalist.filter((item) => {
                        return audioTypes.indexOf(item.type.toLowerCase()) != -1;
                      })" :key="'4' + index">
                        <div class="mask">
                          <i class="el-icon-delete" style="color: #ffffff; font-size: 16px"
                            @click="deletefujian(item.id, items)"></i>
                        </div>
                        <audio v-if="audioTypes.indexOf(items.type.toLowerCase()) != -1" style="height:30px" controls>
                          <source :src="imgurl + items.url" type="audio/mpeg" />
                          如果该元素不被浏览器支持，则本段文本被显示。
                        </audio>
                      </div>
                      <!-- 文件上传 -->
                      <div class="file-wrap" v-for="(items, index) in item.datalist" :key="'1' + index">
                        <div class="file"
                          v-if="videoTypes.indexOf(items.type.toLowerCase()) == -1&&picTypes.indexOf(items.type.toLowerCase()) == -1&&audioTypes.indexOf(items.type.toLowerCase()) == -1">
                          <i class="el-icon-tickets"></i>
                          <!-- <span style="margin-left:10px">{{ items.name }}</span> -->
                          <a :href="imgurl + items.url" target="_blank" style="margin-left: 10px">{{ items.name }}</a>
                          <div class="mask">
                            <i class="el-icon-delete" style="color: #ffffff; font-size: 16px"
                              @click="deletefujian(item.id, items)"></i>
                          </div>
                        </div>
                      </div>
                      <!-- 上传按钮 -->
                      <div class="upbtn">
                        <el-button v-if="item.work_template" type="primary" size="mini" style="display: block"
                          @click="downtemplate(item)">
                          <a :href="imgurl + item.work_template" target="_blank" style="color:#fff">下载模板</a>
                        </el-button>
                        <el-button v-else type="info" disabled size="mini" style="display: block"
                          @click="downtemplate(item)">
                          下载模板
                        </el-button>
                        <el-button type="primary" size="mini" style="display: block" @click="openupload(item.id, true)">
                          点击上传</el-button>
                      </div>

                    </div>
                  </div>
                </draggable>
              </el-form>
            </el-row>
          </el-scrollbar>
          <!-- 本地断点续传 -->
           <el-drawer v-if="!BreakPointUpload" title="" :visible.sync="UploadPicture" @opened="openedDrawer" :before-close="handleClose" @close='closeDrawer(currentUploadId)'>
            <div class="usercontent">
              <el-input type="textarea" placeholder="请输入文件说明" v-model="userval" maxlength="500"
                :autosize="{ minRows: 10}" show-word-limit>
              </el-input>
              <!-- <el-button type="primary" @click="confirm(currentUploadId)">确定</el-button> -->
            </div>
            <upimg v-if="UploadPicture" ref="upimg" :upimgload="true" @closeMain="closeMain"></upimg>
          </el-drawer>
          <!-- 阿里云上传 -->
          <el-drawer v-if="BreakPointUpload" title="" :visible.sync="UploadPicture"  :before-close="handleClose" @close='closeDrawer(currentUploadId)'>
            <div class="usercontent">
              <el-input type="textarea" placeholder="请输入文件说明" v-model="userval" maxlength="500"
                :autosize="{ minRows: 10}" show-word-limit>
              </el-input>
              <!-- <el-button type="primary" @click="confirm(currentUploadId)">确定</el-button> -->
            </div>
            <ossUploader v-if="UploadPicture"  @closeMain="closeMain" :sourceType=4></ossUploader>
          </el-drawer>
          <!-- 提交按钮 -->
          <div class="tc">
            <div class="submit" @click="submit" v-if="!answerAgain">提交</div>
            <div class="submit" @click="subagain" v-else>二次提交</div>
          </div>
        </div>
      </div>
    </div>
    <!-- <Footer></Footer> -->
  </div>
</template>

<script>
  import draggable from "vuedraggable";
  import {
    debounce
  } from "throttle-debounce";
  import {
    saveAs
  } from "file-saver";
  import ClipboardJS from "clipboard";
  import render from "@/components/render/render";
  import JsonDrawer from "./JsonDrawer";
  import RightPanel from "./RightPanel";
  import {
    inputComponents,
    selectComponents,
    layoutComponents,
    formConf,
  } from "@/components/generator/config_answer";
  import {
    exportDefault,
    beautifierConf,
    isNumberStr,
    titleCase,
    deepClone,
  } from "@/utils/index";
  import {
    makeUpHtml,
    vueTemplate,
    vueScript,
    cssStyle,
  } from "@/components/generator/html";
  import {
    makeUpJs
  } from "@/components/generator/js";
  import {
    makeUpCss
  } from "@/components/generator/css";
  import drawingDefalut from "@/components/generator/drawingDefalut";
  import logo from "@/assets/logo.png";
  import CodeTypeDialog from "./CodeTypeDialog";
  import DraggableItem from "./DraggableItem3";
  import {
    getDrawingList,
    saveDrawingList,
    getIdGlobal,
    saveIdGlobal,
    getFormConf,
  } from "@/utils/db";
  import loadBeautifier from "@/utils/loadBeautifier";
  import items from "../../components/Kindeditor/config/items";

  let beautifier;
  const emptyActiveData = {
    style: {},
    autosize: {},
  };
  let oldActiveId;
  let tempActiveData;
  const drawingListInDB = getDrawingList();
  const formConfInDB = getFormConf();
  const idGlobal = getIdGlobal();

  export default {
    components: {
      draggable,
      render,
      JsonDrawer,
      RightPanel,
      CodeTypeDialog,
      DraggableItem,
    },
    data() {
      return {
        upimgload: false,
        dragOptions: {
          sort: false, //定义生成的表单是否可以拖拽
        },
        logo,
        idGlobal,
        formConf,
        inputComponents,
        selectComponents,
        layoutComponents,
        labelWidth: 100,
        drawingList: drawingDefalut,
        drawingData: {},
        activeId: drawingDefalut[0].formId,
        drawerVisible: false,
        formData: {},
        dialogVisible: false,
        jsonDrawerVisible: false,
        generateConf: null,
        showFileName: false,
        activeData: drawingDefalut[0],
        saveDrawingListDebounce: debounce(340, saveDrawingList),
        saveIdGlobalDebounce: debounce(340, saveIdGlobal),
        questionForm: [],
        explain: "", //自定义的作业说明
        answerAgain: false,
        answerId: "", //二次答题的答案ID 二次提交必要参数
        activeNames: ["1"],
        answerAgain: false,
        workInfoAll: "", //作业全部信息
        workInfo: "", //问卷信息
        workTheme: "", //作业主题
        beginTime: "", //开始答卷的时间戳
        powers: "", //二级能力标准
        UploadPicture: false, //上传图片
        Uploadname: "",
        obg: false,
        // kejianlist: [],
        imgurl: this.downloadURL,
        imgUrl: 'this.src="' + require("../../assets/images/home/avatar.png") + '"',
        fujianList: [], //附件题数组
        videoTypes: [
          "mp4",
          "3gp",
          "m4v",
          "mkv",
        ],
        audioTypes: ["wav", "mp3", "aac", ],
        picTypes: ["bmp", "gif", "png", "jpg", "jpeg"],
        fileTypes: ["doc", "docx", "ppt", "pptx", "xls", "xlsx", "pdf", "txt", "wps", "zip", "rar", "mts"],
        currentUploadId: null,
        uploadData: [], //上传的数据
        // showGuide: true,
        // identity: null,
        prevInfo: {
          prevPath: '/',
          prevPage: '课程详情',
          ancestryPath: '/',
          ancestryPage: '我的课程',
        }, //上个页面名称
        userval: ''
      };
    },
    computed: {
      prevPage() {
        if (this.$route.query.from == 'course') {
          return this.prevInfo = {
            prevPath: `/course/${this.$route.query.courseId}?index=0`,
            prevPage: '课程详情',
            ancestryPath: 'course',
            ancestryPage: '我的课程',
          }
        } else {
          return this.prevInfo = {
            prevPath: `/mytask/detail/${this.workInfoAll.themeId}`,
            prevPage: this.workInfoAll.themeName,
            ancestryPath: 'mytask',
            ancestryPage: '我的任务',
          }
        }
      }
    },
    watch: {
      $route: {
        handler(newval, oldval) {
          console.log('newval', newval)
          this.UploadPicture = false
        },
        deep: true
      },
      // eslint-disable-next-line func-names
      "activeData.__config__.label": function (val, oldVal) {
        if (
          this.activeData.placeholder === undefined ||
          !this.activeData.__config__.tag ||
          oldActiveId !== this.activeId
        ) {
          return;
        }
        this.activeData.placeholder =
          this.activeData.placeholder.replace(oldVal, "") + val;
      },
      activeId: {
        handler(val) {
          oldActiveId = val;
        },
        immediate: true,
      },
      drawingList: {
        handler(val) {
          this.saveDrawingListDebounce(val);
          if (val.length === 0) this.idGlobal = 100;
        },
        deep: true,
      },

      idGlobal: {
        handler(val) {
          this.saveIdGlobalDebounce(val);
        },
        immediate: true,
      },
    },
    mounted() {
      if (Array.isArray(drawingListInDB) && drawingListInDB.length > 0) {
        this.drawingList = drawingListInDB;
      } else {
        this.drawingList = drawingDefalut;
      }
      this.activeFormItem(this.drawingList[0]);
      if (formConfInDB) {
        this.formConf = formConfInDB;
      }
      loadBeautifier((btf) => {
        beautifier = btf;
      });
      // this.identity = sessionStorage.getItem("role")
      // this.$store.commit('changestatus', true)

    },
    activated() {
      //清空附件题数组，不然会叠加
      this.uploadData = [], //上传的数据

        this.fujianList = [];
      this.getpowers();
      this.getForm();
      // this.identity = sessionStorage.getItem("role")
      // this.$store.commit('changestatus', true)

    },
    methods: {
      // 关闭右侧上传抽屉提示弹窗
      handleClose(done) {
        this.$confirm('确认关闭？')
          .then(_ => {
            done();
          })
          .catch(_ => {});
      },
      //   close() {
      //     this.showGuide = false
      // this.$store.commit('changestatus', false)

      //   },
      // confirm(id) {
      //   console.log('当前操作的题目id', id);
      //   console.log('当前用户输入', this.userval);
      //   let ind = this.fujianList.findIndex(item => item.id == id);
      //   this.fujianList[ind].explain = this.userval;
      //   let ind2 = this.uploadData.findIndex(item => item.id == id);
      //   this.$set(this.uploadData[ind2], 'explain', this.userval)
      // },
      //上传文件
      openupload(questid, flag) {
        // console.log("当前题目id", questid);
        this.currentUploadId = questid;
        this.UploadPicture = flag;
        if (this.myBrowser() == "Chrome") {
          this.upimgload = false;
        } else {
          this.upimgload = true;
        }
        //回显附件题的用户说明
        let ind = this.fujianList.findIndex(item => item.id == questid);
        this.userval = this.fujianList[ind].explain || ''
      },
      openedDrawer() {
        this.$refs.upimg.$el.querySelector("input").click();
      },
      closeDrawer(id) {
        console.log('当前操作的题目id', id);
        console.log('当前用户输入', this.userval);
        let ind = this.fujianList.findIndex(item => item.id == id);
        this.fujianList[ind].explain = this.userval;
        let ind2 = this.uploadData.findIndex(item => item.id == id);
        this.$set(this.uploadData[ind2], 'explain', this.userval)
      },
      closeMain(val) {
        console.log(val);
        //封装附件题并添加到fujianlist
        let currentQuest = {};
        currentQuest.id = this.currentUploadId;
        currentQuest.url = val.filemd5;
        currentQuest.type = val.filemd5.split("_")[2];
        currentQuest.name = val.name;
        this.fujianList.map((item) => {
          if (item.id == currentQuest.id) {
            item.datalist.push(currentQuest);
            if (this.picTypes.indexOf(currentQuest.type.toLowerCase()) != -1) {
              item.srclist.push(this.imgurl + currentQuest.url);
            }
          }
        });
        // console.log("fujianlist---", this.fujianList);
        //封装附件题答案用于提交时候的附件题参数
        let obj = {};
        obj.id = this.currentUploadId;
        obj.result = [];
        let obj2 = {};
        obj2.id = val.filemd5;
        obj2.type = val.filemd5.split("_")[2];
        obj2.name = val.name;
        obj.result.push(obj2);
        this.uploadData.push(obj);
        // console.log("上传的数据==", this.uploadData);
        this.uploadData = this.process(this.uploadData);
        // console.log("去重后的上传数据==", this.uploadData);
      },
      process(arr) {
        for (let i = 0; i < arr.length; i++) {
          for (let j = 0; j < arr.length; j++) {
            if (arr[i] === arr[j]) {
              continue;
            } else {
              if (arr[i].id == arr[j].id) {
                arr[i].result = arr[i].result.concat(arr[j].result);
                arr.splice(j, 1);
              }
            }
          }
        }
        return arr;
      },
      //删除附件题
      deletefujian(currentid, currentfj) {
        // console.log("当前操作的附件题id--", currentid);
        // console.log("当前要删除的附件--", currentfj);
        //删除附件题显示
        for (let i = 0; i < this.fujianList.length; i++) {
          if (this.fujianList[i].id == currentid) {
            let index = this.fujianList[i].datalist.findIndex((item) => item.name == currentfj.name)
            this.fujianList[i].datalist.splice(index, 1)
          }
        }
        // console.log("附件题fujianlist--", this.fujianList)

        //删除提交问卷的该附件题参数
        for (let i = 0; i < this.uploadData.length; i++) {
          if (this.uploadData[i].id == currentid) {
            let index = this.uploadData[i].result.findIndex(item => item.name == currentfj.name)
            this.uploadData[i].result.splice(index, 1)
          }
        }

        // console.log("上传的附件题参数uploadData--", this.uploadData)
      },
      //获取问卷顶部能力标准
      getpowers() {
        this.$Api.Form.getSecondPower(this.$route.query.questionId)
          .then((value) => {
            // console.log('二级能力标准',value)
            let str = "";
            value.data.map((item) => {
              return (str += item.title + ",");
            });
            this.powers = str.slice(0, str.length - 1);
          })
          .catch((reason) => {
            console.log(reason);
          });
      },
      //时分秒换算
      date_format(micro_second) {
        // 总秒数
        var second = Math.floor(micro_second / 1000);
        var min = Math.floor((second / 60) % 60);
        // 秒
        var sec = Math.floor(second % 60);
        return min + "分钟" + sec + "秒";
      },
      //获取问卷详情
      getForm() {
        this.$Api.Form.getStuForm(this.$route.query.questionId)
          .then((value) => {
            this.workInfoAll = value.data;
            // console.log("获取问卷初始详情--", value)
            this.workTheme = value.data.themeName;
            let content = JSON.parse(value.data.content);
            this.workInfo = JSON.parse(value.data.title);
            content.map((item) => {
              if (item.__config__.tag == "tinymce") {
                this.explain = item.__config__.defaultValue;
              }
              if (item.__config__.tag == "el-checkbox-group") {
                item.__config__.defaultValue = [];
              } else {
                item.__config__.defaultValue = "";
              }
              if (item.__config__.tag == "el-input") {
                item.readonly = false;
              } else {
                item.disabled = false;
              }
            });
            content = content.filter((item) => {
              return item.__config__.tag != "tinymce";
            });
            // console.log("过滤作业说明后的问卷详情--", content);
            //判断附件题，添加到fujianlist
            content.map((item) => {
              if (item.__config__.tag == "el-upload") {
                // console.log(item)
                let obj = {};
                obj.id = item.__vModel__;
                obj.title = item.__config__.label;
                obj.type = "";
                obj.datalist = [];
                obj.srclist = [];
                obj.explain = '';
                obj.work_template = item.__config__.wrok_template;
                obj.work_template_name = item.__config__.wrok_template_name
                this.fujianList.push(obj);
              }
            });
            //过滤附件题
            content = content.filter((item) => {
              return item.__config__.tag != "el-upload";
            });
            // console.log("过滤附件题后的问卷详情--", content);
            // console.log("附件题列表--", this.fujianList);
            localStorage.setItem("drawingItems", JSON.stringify(content));
            this.drawingList = JSON.parse(localStorage.getItem("drawingItems"));
            this.beginTime = new Date().getTime();
          })
          .catch((reason) => {
            console.log(reason);
          });
      },
      //交卷
      submit() {
        let allQuest = this.drawingList.concat(this.fujianList);
        let endtime =
          `${this.workInfo.endTime.split("-")[0]}-${this.workInfo.endTime.split("-")[1]}-${this.workInfo.endTime.split("-")[2]} ${this.workInfo.endTime.split("-")[3]}`
        // 判断有无补交
        if (this.workInfo.subagain) {
          console.log('有补交')
          for (let i = 0; i < allQuest.length; i++) {
            //  allQuest[i].__config__.defaultValue.length == 0 || allQuest[i].__config__.defaultValue == "" || allQuest[i].datalist.length==0
            if (
              allQuest[i].__config__ ?
              allQuest[i].__config__.defaultValue.length == 0 ||
              allQuest[i].__config__.defaultValue == "" :
              allQuest[i].datalist.length == 0
            ) {
              this.$alert("请检查是否有没填写的题目", "提示", {
                confirmButtonText: "确定",
              });
              break;
            } else {
              this.$confirm("是否交卷？", "提示", {
                  confirmButtonText: "确定",
                  cancelButtonText: "取消",
                  type: "info",
                })
                .then(() => {
                  this.questionForm = JSON.parse(
                    localStorage.getItem("drawingItems")
                  );
                  // console.log(this.questionForm);
                  let resultArr = [];
                  for (var i = 0; i < this.questionForm.length; i++) {
                    var obj = {};
                    obj.id = this.questionForm[i].__vModel__;
                    if (
                      this.questionForm[i].__config__.tag == "el-checkbox-group"
                    ) {
                      obj.result = this.questionForm[i].__config__.defaultValue.sort();
                    } else {
                      obj.result = this.questionForm[i].__config__.defaultValue;
                    }
                    // obj.result = this.questionForm[i].__config__.defaultValue
                    resultArr.push(obj);
                  }
                  let endTime = new Date().getTime();
                  let times = endTime - this.beginTime;
                  let time2 = this.date_format(times);
                  // console.log("合并前答案", resultArr);

                  resultArr = resultArr.concat(this.uploadData);
                  // console.log("合并后答案", resultArr);
                  let params = {
                    questionnaireId: this.$route.query.questionId, //问卷id
                    // userId: JSON.parse(sessionStorage.getItem("userinfo")).id, //用户id
                    userId: this.$store.state.user.userInfo.id, //用户id
                    content: JSON.stringify(resultArr), //答题内容及答案
                    answerDuration: time2, //答题时长
                    supplement: this.$route.query.isSubAgain
                  };
                  const loading = this.$loading({
                    lock: true,
                    text: '提交中',
                  });
                  console.log("参数--", params);
                  this.$Api.Form.submitForm(params)
                    .then((res) => {
                      console.log('提交结果----', res);
                      if (res.code == 200) {
                        this.$message({
                          type: "success",
                          message: "成功",
                        });
                        loading.close();
                        let breadLength = this.$store.state.basics.breadList.length
                        this.$router.push({
                          path: "/view",
                          query: {
                            questionId: this.$route.query.questionId,
                            userId: this.$route.query.userId,
                            userType: "student",
                            breadNumber: breadLength,
                          },
                        });
                      } else {
                        this.$alert("提交失败,请稍后再试", '警告', {
                          confirmButtonText: '确定',
                          type: 'warning'
                        })
                      }
                    })
                    .catch((err) => {
                      console.log(err);
                    });
                })
                .catch((err) => {
                  console.log('交卷失败',err)
                  this.$message({
                    type: "info",
                    message: "取消成功",
                  });
                });
            }
          }
        } else {
          if (Date.now() > new Date(endtime).getTime()) {
            this.$message({
              message: '不在提交时间范围内，不能提交',
              type: 'warning'
            });
          } else {
            for (let i = 0; i < allQuest.length; i++) {
              //  allQuest[i].__config__.defaultValue.length == 0 || allQuest[i].__config__.defaultValue == "" || allQuest[i].datalist.length==0
              if (
                allQuest[i].__config__ ?
                allQuest[i].__config__.defaultValue.length == 0 ||
                allQuest[i].__config__.defaultValue == "" :
                allQuest[i].datalist.length == 0
              ) {
                this.$alert("请检查是否有没填写的题目", "提示", {
                  confirmButtonText: "确定",
                });
                break;
              } else {
                this.$confirm("是否交卷？", "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "info",
                  })
                  .then(() => {
                    console.log('确定')
                    this.questionForm = JSON.parse(
                      localStorage.getItem("drawingItems")
                    );
                    // console.log(this.questionForm);
                    let resultArr = [];
                    for (var i = 0; i < this.questionForm.length; i++) {
                      var obj = {};
                      obj.id = this.questionForm[i].__vModel__;
                      if (
                        this.questionForm[i].__config__.tag == "el-checkbox-group"
                      ) {
                        obj.result = this.questionForm[i].__config__.defaultValue.sort();
                      } else {
                        obj.result = this.questionForm[i].__config__.defaultValue;
                      }
                      // obj.result = this.questionForm[i].__config__.defaultValue
                      resultArr.push(obj);
                    }
                    let endTime = new Date().getTime();
                    let times = endTime - this.beginTime;
                    let time2 = this.date_format(times);
                    // console.log("合并前答案", resultArr);

                    resultArr = resultArr.concat(this.uploadData);
                    // console.log("合并后答案", resultArr);
                    let params = {
                      questionnaireId: this.$route.query.questionId, //问卷id
                      // userId: JSON.parse(sessionStorage.getItem("userinfo")).id, //用户id
                      userId: this.$store.state.user.userInfo.id, //用户id
                      content: JSON.stringify(resultArr), //答题内容及答案
                      answerDuration: time2, //答题时长
                      supplement: this.$route.query.isSubAgain
                    };
                    const loading = this.$loading({
                      lock: true,
                      text: '提交中',
                    });
                    console.log("参数--", params);
                    this.$Api.Form.submitForm(params)
                      .then((res) => {
                        console.log('提交结果----', res);
                        if (res.code == 200) {
                          this.$message({
                            type: "success",
                            message: "成功",
                          });
                          loading.close();
                          this.$router.push({
                            path: "/view",
                            query: {
                              questionId: this.$route.query.questionId,
                              userId: this.$route.query.userId,
                              userType: "student",
                            },
                          });
                        } else {
                          this.$alert("提交失败,请稍后再试", '警告', {
                            confirmButtonText: '确定',
                            type: 'warning'
                          })
                        }
                      })
                      .catch((err) => {
                        console.log(err);
                      });
                  })
                  .catch((err) => {
                    console.log(err)
                    this.$message({
                      type: "info",
                      message: "取消成功",
                    });
                  });
              }
            }
          }
        }


      },
      activeFormItem(currentItem) {
        this.activeData = currentItem;
        this.activeId = currentItem.__config__.formId;
      },
      onEnd(obj) {
        if (obj.from !== obj.to) {
          this.activeData = tempActiveData;
          this.activeId = this.idGlobal;
        }
      },
      addComponent(item) {
        const clone = this.cloneComponent(item);
        this.drawingList.push(clone);
        this.activeFormItem(clone);
      },
      cloneComponent(origin) {
        const clone = deepClone(origin);
        const config = clone.__config__;
        config.span = this.formConf.span; // 生成代码时，会根据span做精简判断
        this.createIdAndKey(clone);
        clone.placeholder !== undefined && (clone.placeholder += config.label);
        tempActiveData = clone;
        return tempActiveData;
      },
      createIdAndKey(item) {
        const config = item.__config__;
        config.formId = ++this.idGlobal;
        config.renderKey = `${config.formId}${+new Date()}`; // 改变renderKey后可以实现强制更新组件
        if (config.layout === "colFormItem") {
          item.__vModel__ = `field${this.idGlobal}`;
        } else if (config.layout === "rowFormItem") {
          config.componentName = `row${this.idGlobal}`;
          !Array.isArray(config.children) && (config.children = []);
          delete config.label; // rowFormItem无需配置label属性
        }
        if (Array.isArray(config.children)) {
          config.children = config.children.map((childItem) =>
            this.createIdAndKey(childItem)
          );
        }
        return item;
      },
      AssembleFormData() {
        this.formData = {
          fields: deepClone(this.drawingList),
          ...this.formConf,
        };
      },
      generate(data) {
        const func = this[`exec${titleCase(this.operationType)}`];
        this.generateConf = data;
        func && func(data);
      },
      execRun(data) {
        this.AssembleFormData();
        this.drawerVisible = true;
      },
      execDownload(data) {
        const codeStr = this.generateCode();
        const blob = new Blob([codeStr], {
          type: "text/plain;charset=utf-8",
        });
        saveAs(blob, data.fileName);
      },
      execCopy(data) {
        document.getElementById("copyNode").click();
      },
      drawingItemCopy(item, list) {
        let clone = deepClone(item);
        clone = this.createIdAndKey(clone);
        list.push(clone);
        this.activeFormItem(clone);
      },
      drawingItemDelete(index, list) {
        list.splice(index, 1);
        this.$nextTick(() => {
          const len = this.drawingList.length;
          if (len) {
            this.activeFormItem(this.drawingList[len - 1]);
          }
        });
      },
      generateCode() {
        const {
          type
        } = this.generateConf;
        this.AssembleFormData();
        const script = vueScript(makeUpJs(this.formData, type));
        const html = vueTemplate(makeUpHtml(this.formData, type));
        const css = cssStyle(makeUpCss(this.formData));
        return beautifier.html(html + script + css, beautifierConf.html);
      },
      tagChange(newTag) {
        newTag = this.cloneComponent(newTag);
        const config = newTag.__config__;
        newTag.__vModel__ = this.activeData.__vModel__;
        config.formId = this.activeId;
        config.span = this.activeData.__config__.span;
        this.activeData.__config__.tag = config.tag;
        this.activeData.__config__.tagIcon = config.tagIcon;
        this.activeData.__config__.document = config.document;
        if (
          typeof this.activeData.__config__.defaultValue ===
          typeof config.defaultValue
        ) {
          config.defaultValue = this.activeData.__config__.defaultValue;
        }
        Object.keys(newTag).forEach((key) => {
          if (this.activeData[key] !== undefined) {
            newTag[key] = this.activeData[key];
          }
        });
        this.activeData = newTag;
        this.updateDrawingList(newTag, this.drawingList);
      },
      updateDrawingList(newTag, list) {
        const index = list.findIndex(
          (item) => item.__config__.formId === this.activeId
        );
        if (index > -1) {
          list.splice(index, 1, newTag);
        } else {
          list.forEach((item) => {
            if (Array.isArray(item.__config__.children))
              this.updateDrawingList(newTag, item.__config__.children);
          });
        }
      },
      refreshJson(data) {
        this.drawingList = deepClone(data.fields);
        delete data.fields;
        this.formConf = data;
      },
      // 下载附件模板
      downtemplate(item) {
        console.log("下载附件模板", item)
      }
    },
  };
</script>

<style lang="less" scoped>
  // @import "@/styles/home";

  .answer-mode {
    // background: #fafafa;
    max-width: 1920px;
    min-width: 1100px;
    // transform: scale(1);
    margin: 0 auto;

    // .guide {
    //   position: fixed;
    //   top: 0;
    //   left: 0;
    //   // margin: 50px;
    //   width: 100%;
    //   height: 100%;
    //   z-index: 99;
    //   background-color: rgba(0, 0, 0, .6);

    //   .step_one {
    //     position: absolute;
    //     top: 500px;
    //     right: 300px;
    //   }
    // }

    .container {
      margin: 0 auto 120px;
      padding-bottom: 50px;
      box-shadow: 0px 2px 10px 0px #f5f8fa;
      border-radius: 8px;

      .explain {
        padding: 0 47px 20px;
        background: #ffffff;
        border-radius: 8px;
        margin-bottom: 20px;

        .explain_title {
          font-size: 13px;
          font-weight: bold;
          line-height: 49px;
          border-bottom: 1px solid #F5F4F4;
          margin-bottom: 15px;

          &::before {
            content: ' ';
            display: inline-block;
            width: 4px;
            height: 21px;
            background-color: #508EF9;
            margin-right: 11px;
            border-radius: 2px;
            vertical-align: -5px;
          }
        }

        .explain_item {
          padding-left: 15px;
          color: #999999;
          font-size: 12px;
          line-height: 35px;
        }
      }

      .center-board {
        margin: 0;
        padding: 0 47px 20px;
        background: #ffffff;
        border-radius: 8px;

        .workContent {
          border-bottom: 1px solid #F5F4F4;
          font-size: 13px;
          font-weight: bold;
          margin-bottom: 25px;

          span {
            line-height: 49px;
            padding: 0 20px;
            position: relative;
            display: inline-block;
            color: #508EF9;

            &::after {
              position: absolute;
              content: ' ';
              display: inline-block;
              bottom: 0;
              left: 0;
              width: 100%;
              height: 1px;
              background-color: #508EF9;
            }
          }
        }

        .center-scrollbar {
          border: 0;
        }

        .center-board-row {
          form {
            .drawing-board {
              .drawing-item-delete {
                display: none;
              }

              .el-collapse {
                border-bottom: none;
                border-top: none;
              }

              .fujian {
                margin: 10px 0;

                .fujian_title {
                  .fjexplin {
                    margin-bottom: 10px;
                    word-break: break-all;
                    white-space: pre-line;
                  }

                  .file {
                    position: relative;
                    padding-left: 10px;
                    margin: 10px 0px;
                    height: 50px;
                    display: flex;
                    align-items: center;
                    background: #EBEEF5;
                    width: 50%;
                    border-radius: 3px; // cursor: pointer;

                    .mask {
                      position: absolute;
                      right: 0;
                      top: 0;
                      width: 30px;
                      height: 30px;
                      background: rgba(0, 0, 0, 0.7);
                      z-index: 9;
                      visibility: hidden;
                      display: flex;
                      align-items: center;
                      justify-content: space-around;
                      border-radius: 0 0 0 5px;
                    }

                    &:hover .mask {
                      visibility: visible;
                    }

                  }

                  .mask-wrap {
                    position: relative;
                    width: 200px;
                    height: 150px;
                    border-radius: 5px;
                    margin: 0 20px 20px 0;
                    overflow: hidden;

                    .mask {
                      position: absolute;
                      right: 0;
                      top: 0;
                      width: 30px;
                      height: 30px;
                      background: rgba(0, 0, 0, 0.7);
                      z-index: 9;
                      visibility: hidden;
                      display: flex;
                      align-items: center;
                      justify-content: space-around;
                      border-radius: 0 0 0 5px;
                    }

                    &:hover .mask {
                      visibility: visible;
                    }
                  }

                  .audio-wrap {
                    position: relative;
                    width: 300px;
                    height: 50px;
                    display: flex;
                    align-items: center;
                    justify-content: space-around;
                    background: #EBEEF5;
                    box-sizing: border-box;
                    padding: 0 20px;
                    margin: 10px 0;
                    border-radius: 5px;

                    .mask {
                      position: absolute;
                      right: 0;
                      top: 0;
                      width: 30px;
                      height: 30px;
                      background: rgba(0, 0, 0, 0.7);
                      z-index: 9;
                      visibility: hidden;
                      display: flex;
                      align-items: center;
                      justify-content: space-around;
                      border-radius: 0 0 0 5px;
                    }

                    &:hover .mask {
                      visibility: visible;
                    }
                  }

                  .video-wrap {
                    position: relative;
                    width: 200px;
                    height: 150px;
                    border-radius: 5px;
                    margin: 0 20px 20px 0;
                    overflow: hidden;

                    .mask {
                      position: absolute;
                      right: 0;
                      top: 0;
                      width: 30px;
                      height: 30px;
                      background: rgba(0, 0, 0, 0.7);
                      z-index: 9;
                      visibility: hidden;
                      display: flex;
                      align-items: center;
                      justify-content: space-around;
                      border-radius: 0 0 0 5px;
                    }

                    &:hover .mask {
                      visibility: visible;
                    }
                  }

                  .upbtn {
                    display: flex;
                    align-items: center;
                  }
                }
              }
            }
          }
        }

        /deep/ .el-drawer__body {
          overflow-y: auto;
        }

        .usercontent {
          height: 300px;
          overflow-y: auto;
          padding: 0 30px;

          &::-webkit-scrollbar {
            display: none;
          }

          .el-button {
            margin-top: 20px;
          }
        }

        .userbtn {
          display: flex;
          align-items: center;
        }
      }

      .submit {
        display: inline-block;
        background-color: #508EF9;
        padding: 0 42px;
        border-radius: 4px;
        text-align: center;
        line-height: 31px;
        color: #ffffff;
        font-size: 12px;
        margin: 20px auto;
        cursor: pointer;
      }
    }

    @media screen and (min-width:1250px) {
      .container {

        .explain {
          padding: 0 57px 25px;
          margin-bottom: 24px;

          .explain_title {
            font-size: 16px;
            line-height: 60px;

            &::before {
              width: 5px;
              height: 25px;
            }
          }

          .explain_item {
            padding-left: 18px;
            font-size: 13px;
          }

        }

        .center-board {
          padding: 0 57px 40px;

          .workContent {
            font-size: 16px;

            span {
              line-height: 60px;
              padding: 0 25px;
            }
          }

        }

        .submit {
          padding: 0 52px;
          line-height: 38px;
          font-size: 15px;
        }
      }
    }
    /deep/ .el-checkbox{
      white-space: pre-wrap;
      display: flex;
      align-items: center;
      margin-bottom: 10px;
    }

  }
</style>